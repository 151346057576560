export const PRODUCT_CHANGE_TAP = "PRODUCT_CHANGE_TAP";
export const PRODUCT_PARAMS = "PRODUCT_PARAMS";
export const PRODUCT_BOOK_DATA_LOADING = "PRODUCT_BOOK_DATA_LOADING";
export const PRODUCT_BOOK_DATA = "PRODUCT_BOOK_DATA";
export const PRODUCT_BOOK_DATA_SECOND = "PRODUCT_BOOK_DATA_SECOND";
export const PRODUCT_BOOK_DATA_THIRD = "PRODUCT_BOOK_DATA_THIRD";
export const PRODUCT_BOOK_DATA_FOURTH = "PRODUCT_BOOK_DATA_FOURTH";
export const PRODUCT_BOOK_DEPTH = "PRODUCT_BOOK_DEPTH";
export const PRODUCT_BOOK_SECOND = "PRODUCT_BOOK_SECOND";
export const PRODUCT_BOOK_CATE_SEQ = "PRODUCT_BOOK_CATE_SEQ";
export const PRODUCT_NEW_BOOK_DATA = "PRODUCT_NEW_BOOK_DATA";
export const PRODUCT_NEW_BOOK_CODES = "PRODUCT_NEW_BOOK_CODES";
export const PRODUCT_NEW_BOOK_CODEM = "PRODUCT_NEW_BOOK_CODEM";
export const PRODUCT_NEW_BOOK_CODEL = "PRODUCT_NEW_BOOK_CODEL";
export const PRODUCT_BOOK_BRAN_NM = "PRODUCT_BOOK_BRAN_NM";
export const PRODUCT_BOOK_CHECKED = "PRODUCT_BOOK_CHECKED";
export const PRODUCT_BOOK_SECOND_DATA_LOADING =
  "PRODUCT_BOOK_SECOND_DATA_LOADING";
export const PRODUCT_BOOK_THIRD_DATA_LOADING =
  "PRODUCT_BOOK_THIRD_DATA_LOADING";
export const PRODUCT_BOOK_FOURTH_DATA_LOADING =
  "PRODUCT_BOOK_FOURTH_DATA_LOADING";
export const PRODUCT_BOOK_SECOND_DATA = "PRODUCT_BOOK_SECOND_DATA";
export const PRODUCT_BOOK_CATE_DELETE = "PRODUCT_BOOK_CATE_DELETE";
export const PRODUCT_BRAND_IDX = "PRODUCT_BRAND_IDX";
export const PRODUCT_BOOK_KIND = "PRODUCT_BOOK_KIND";
export const PRODUCT_BOOK_CREATE_ABLE = "PRODUCT_BOOK_CREATE_ABLE";

export const changeTap = (activeTap) => ({
  type: PRODUCT_CHANGE_TAP,
  activeTap,
});
export const changeParams = (params) => ({
  type: PRODUCT_PARAMS,
  params: params,
});

export const bookProductGridLoading = (isGridLoading) => ({
  type: PRODUCT_BOOK_DATA_LOADING,
  isGridLoading: isGridLoading,
});

export const bookProductSecondGridLoading = (isSecondGridLoading) => ({
  type: PRODUCT_BOOK_SECOND_DATA_LOADING,
  isSecondGridLoading: isSecondGridLoading,
});

export const bookProductThirdGridLoading = (isThirdGridLoading) => ({
  type: PRODUCT_BOOK_THIRD_DATA_LOADING,
  isThirdGridLoading: isThirdGridLoading,
});

export const bookProductFourthGridLoading = (isFourthGridLoading) => ({
  type: PRODUCT_BOOK_FOURTH_DATA_LOADING,
  isFourthGridLoading: isFourthGridLoading,
});

export const bookProductGrid = (bookData) => ({
  type: PRODUCT_BOOK_DATA,
  bookData: bookData,
});

export const bookProductBrandNm = (branNm) => ({
  type: PRODUCT_BOOK_BRAN_NM,
  branNm: branNm,
});

export const newBookProductGrid = (newBookData) => ({
  type: PRODUCT_NEW_BOOK_DATA,
  newBookData: newBookData,
});

export const bookProductCodeS = (BookCodeS) => ({
  type: PRODUCT_NEW_BOOK_CODES,
  BookCodeS: BookCodeS,
});

export const bookProductCodeM = (BookCodeM) => ({
  type: PRODUCT_NEW_BOOK_CODEM,
  BookCodeM: BookCodeM,
});

export const bookProductCodeL = (BookCodeL) => ({
  type: PRODUCT_NEW_BOOK_CODEL,
  BookCodeL: BookCodeL,
});

export const bookProductGridSecond = (secondList) => ({
  type: PRODUCT_BOOK_DATA_SECOND,
  secondList: secondList,
});
export const bookProductGridThird = (thirdList) => ({
  type: PRODUCT_BOOK_DATA_THIRD,
  thirdList: thirdList,
});
export const bookProductGridFourth = (fourthList) => ({
  type: PRODUCT_BOOK_DATA_FOURTH,
  fourthList: fourthList,
});
export const bookProductDepth = (depthLevel) => ({
  type: PRODUCT_BOOK_DEPTH,
  depthLevel: depthLevel,
});
export const bookProductSecondSelect = (secondSelect) => ({
  type: PRODUCT_BOOK_SECOND,
  secondSelect: secondSelect,
});

export const bookProductCateSeq = (cateSeq) => ({
  type: PRODUCT_BOOK_CATE_SEQ,
  cateSeq: cateSeq,
});
export const bookProductCheck = (checkItems) => ({
  type: PRODUCT_BOOK_CHECKED,
  checkItems: checkItems,
});

export const bookProductSelectBrand = (brandData) => ({
  type: PRODUCT_BOOK_SECOND_DATA,
  brandData: brandData,
});
export const bookProductCateDel = (cateDel) => ({
  type: PRODUCT_BOOK_CATE_DELETE,
  cateDel: cateDel,
});
export const bookProductSelectRowIdx = (brandIdx, gfIdx, create, update) => ({
  type: PRODUCT_BRAND_IDX,
  brandIdx: brandIdx,
  gfIdx: gfIdx,
  create: create == true ? create : false,
  update: update == true ? update : false,
});
export const bookProductbookKind = (bookKind) => ({
  type: PRODUCT_BOOK_KIND,
  bookKind: bookKind,
});

export const bookProductGfCreateAble = (createAble) => ({
  type: PRODUCT_BOOK_CREATE_ABLE,
  createAble: createAble,
});

export default function reducer(
  state = {
    activeTap: "1",
    secondSelect: [],
    params: [],
    isGridLoading: true,
    isSecondGridLoading: true,
    isThirdGridLoading: true,
    isFourthGridLoading: true,
    bookData: [],
    secondList: [],
    thirdList: [],
    fourthList: [],
    cateSeq: "",
    branNm: "",
    checkItems: [],
    brandData: [],
    cateDel: false,
    brandIdx: 0,
    gfIdx: 0,
    create: false,
    update: false,
    bookKind: "가격표",
    createAble: false,
  },
  action
) {
  switch (action.type) {
    case PRODUCT_CHANGE_TAP:
      return {
        ...state,
        activeTap: action.activeTap,
      };
    case PRODUCT_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case PRODUCT_BOOK_DATA_LOADING:
      return {
        ...state,
        isGridLoading: action.isGridLoading,
      };
    case PRODUCT_BOOK_SECOND_DATA_LOADING:
      return {
        ...state,
        isSecondGridLoading: action.isSecondGridLoading,
      };
    case PRODUCT_BOOK_THIRD_DATA_LOADING:
      return {
        ...state,
        isThirdGridLoading: action.isThirdGridLoading,
      };
    case PRODUCT_BOOK_FOURTH_DATA_LOADING:
      return {
        ...state,
        isFourthGridLoading: action.isFourthGridLoading,
      };
    case PRODUCT_BOOK_DATA:
      return {
        ...state,
        bookData: action.bookData,
      };
    case PRODUCT_BOOK_DATA_SECOND:
      return {
        ...state,
        secondList: action.secondList,
      };
    case PRODUCT_BOOK_DATA_THIRD:
      return {
        ...state,
        thirdList: action.thirdList,
      };
    case PRODUCT_BOOK_DATA_FOURTH:
      return {
        ...state,
        fourthList: action.fourthList,
      };
    case PRODUCT_BOOK_DEPTH:
      return {
        ...state,
        depthLevel: action.depthLevel,
      };
    case PRODUCT_BOOK_SECOND:
      return {
        ...state,
        secondSelect: action.secondSelect,
      };
    case PRODUCT_BOOK_CATE_SEQ:
      return {
        ...state,
        cateSeq: action.cateSeq,
      };
    case PRODUCT_NEW_BOOK_DATA:
      return {
        ...state,
        newBookData: action.newBookData,
      };
    case PRODUCT_NEW_BOOK_CODES:
      return {
        ...state,
        BookCodeS: action.BookCodeS,
      };
    case PRODUCT_NEW_BOOK_CODEM:
      return {
        ...state,
        BookCodeM: action.BookCodeM,
      };
    case PRODUCT_NEW_BOOK_CODEL:
      return {
        ...state,
        BookCodeL: action.BookCodeL,
      };

    case PRODUCT_BOOK_BRAN_NM:
      return {
        ...state,
        branNm: action.branNm,
      };
    case PRODUCT_BOOK_CHECKED:
      return {
        ...state,
        checkItems: action.checkItems,
      };
    case PRODUCT_BOOK_SECOND_DATA:
      return {
        ...state,
        brandData: action.brandData,
      };
    case PRODUCT_BOOK_CATE_DELETE:
      return {
        ...state,
        cateDel: action.cateDel,
      };
    case PRODUCT_BRAND_IDX:
      return {
        ...state,
        brandIdx: action.brandIdx,
        gfIdx: action.gfIdx,
        create: action.create,
        update: action.update,
      };
    case PRODUCT_BOOK_KIND:
      return {
        ...state,
        bookKind: action.bookKind,
      };
    case PRODUCT_BOOK_CREATE_ABLE:
      return {
        ...state,
        createAble: action.createAble,
      };
    default:
  }
  return state;
}
