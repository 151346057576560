export const CHANGE_DCS_MODULE = "CHANGE_DCS_MODULE";
export const CHANGE_SYNC_FLAG_MODULE = "CHANGE_SYNC_FLAG_MODULE";

export const changeDcsMenu = module => ({
  type: CHANGE_DCS_MODULE,
  module
});

export const changeSyncFlag = sync => ({
  type: CHANGE_SYNC_FLAG_MODULE,
  sync : sync
});

export default function reducer(state = {
  module: '',
  sync: false,
}, action) {
  switch (action.type) {
      case CHANGE_DCS_MODULE:
        return {
            ...state,
            module: action.module
        };
      case CHANGE_SYNC_FLAG_MODULE:
        return {
            ...state,
            sync: action.sync
        };
      default:
  }
  return state;
}

