import React, { Fragment, useState ,useEffect} from "react";
import { connect, useSelector , useDispatch} from "react-redux";
import * as Cookies from "js-cookie";
import cookie from 'react-cookies'
import AppMain from "../../Layout/AppMain";
import ResizeDetector from "react-resize-detector";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import UserReducer from "../../../reducers/UserReducer";
import {userLoginTrue, userLoginFalse} from "../../../actions/UserReducer/UserActions";
import { changeDcsMenu } from "../../../reducers/DcsNavReducer";

const MainFunction = () => {
  const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
  const ThemeOptions = useSelector((state) => state.ThemeOptions);
  const userReducer = useSelector((state) => state.UserReducer);
  const UserActions= useSelector((state) => state.UserActions);
  const dispatch = useDispatch();

  //현재 페이지 메뉴(맨마지막 이라서 paramete 추가되면 수정해야할수 있음)
  const pageName = window.location.href.split('/').slice(-1)[0];
   //페이지 처음 들어오면 실행..
   useEffect(() => {
    dispatch(changeDcsMenu(pageName));//책상품관리로 네비게이션 전환
  }, []);


  useEffect(() => {
    const sessionCookie = Cookies.get("session");
    if(sessionCookie != undefined){
      let tempJson = JSON.parse(sessionCookie);
      dispatch(userLoginTrue(tempJson.id,tempJson.name,tempJson.auth));
    }
else{
  dispatch(userLoginFalse({loggedIn: false}));
}

  }, []);

  
 
  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              "app-container app-theme-" + ThemeOptions.colorScheme,
              { "fixed-header": ThemeOptions.enableFixedHeader },
              {
                "fixed-sidebar":
                  ThemeOptions.enableFixedSidebar || width < 1250,
              },
              { "fixed-footer": ThemeOptions.enableFixedFooter },
              {
                "closed-sidebar":
                  ThemeOptions.enableClosedSidebar || width < 1250,
              },
              {
                "closed-sidebar-mobile":
                  ThemeOptions.closedSmallerSidebar || width < 1250,
              },
              { "sidebar-mobile-open": ThemeOptions.enableMobileMenu },
              { "body-tabs-shadow-btn": ThemeOptions.enablePageTabsAlt }
            )}
          >
            <AppMain />
          </div>
        </Fragment>
      )}
    />
  );
};
export default MainFunction;
