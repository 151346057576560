export const DCS_OLD_MODAL_DATA = "DCS_OLD_MODAL_DATA";
export const DCS_NEW_MODAL_DATA = "DCS_NEW_MODAL_DATA";

export const DCS_OLD_BOOK_MODAL_DATA = "DCS_OLD_BOOK_MODAL_DATA";
export const DCS_NEW_BOOK_MODAL_DATA = "DCS_NEW_BOOK_MODAL_DATA";

export const changeOldCategoryData = oldCategoryData => ({
    type: DCS_OLD_MODAL_DATA,
    oldCategoryData,
  });
export const changeNewCategoryData = newCategoryData => ({
  type: DCS_NEW_MODAL_DATA,
  newCategoryData,
});

export const bookOldCategoryData = bookPOldCategoryData => ({
  type: DCS_OLD_BOOK_MODAL_DATA,
  bookPOldCategoryData,
});
export const bookNewCategoryData = bookPNewCategoryData => ({
type: DCS_NEW_BOOK_MODAL_DATA,
bookPNewCategoryData,
});

export default function reducer(state = {
  oldCategoryData: [],
  newCategoryData: [],
  bookPOldCategoryData: [],
  bookPNewCategoryData: [],
}, action) {
  switch (action.type) {
      case DCS_OLD_MODAL_DATA:
        return {
            ...state,
            oldCategoryData: action.oldCategoryData
        };
      case DCS_NEW_MODAL_DATA:
        return {
            ...state,
            newCategoryData: action.newCategoryData
        };
        case DCS_OLD_BOOK_MODAL_DATA:
          return {
              ...state,
              bookPOldCategoryData: action.bookPOldCategoryData
          };
          case DCS_NEW_BOOK_MODAL_DATA:
            return {
                ...state,
                bookPNewCategoryData: action.bookPNewCategoryData
            };
      default:
  }
  return state;
}

