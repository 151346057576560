import { USER_LOGIN_FALSE, USER_LOGIN_TRUE } from "./UserActionTypes";
import * as Cookies from "js-cookie";

export const userLoginTrue = (id,name, auth) => (dispatch) => {
  Cookies.remove("session");
  const cookieData = {
    id: id,
    name: name,
    auth: auth
  };
  Cookies.set("session", cookieData);
  //sessionStorage.setItem("ctxlogin", id);
  return dispatch({
    type: USER_LOGIN_TRUE,
    id: id,
    name: name,
    auth: auth
  });
};

export const userLoginFalse = () => (dispatch) => {
  //sessionStorage.removeItem("ctxlogin");
  Cookies.remove("session");
  return dispatch({
    type: USER_LOGIN_FALSE,
  });
};
