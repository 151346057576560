export const CHANGE_PLUGIN_MENU_STEP = "CHANGE_PLUGIN_MENU_STEP";
export const PREV_PLUGIN_BOOK_STEP = "PREV_PLUGIN_BOOK_STEP";
export const NEXT_PLUGIN_BOOK_STEP = "NEXT_PLUGIN_BOOK_STEP";
export const PREV_PLUGIN_SEARCH_STEP = "PREV_PLUGIN_SEARCH_STEP";
export const NEXT_PLUGIN_SEARCH_STEP = "NEXT_PLUGIN_SEARCH_STEP";
export const CHANGE_PLUGIN_BOOK_STEP = "CHANGE_PLUGIN_BOOK_STEP";
export const CHANGE_PLUGIN_SEARCH_STEP = "CHANGE_PLUGIN_SEARCH_STEP";
export const CHANGE_PLUGIN_BOOK_NAV_DATA = "CHANGE_PLUGIN_BOOK_NAV_DATA";
export const CHANGE_PLUGIN_SEARCH_NAV_DATA = "CHANGE_PLUGIN_SEARCH_NAV_DATA";
export const CHANGE_PLUGIN_SEARCH_ROW_DATA = "CHANGE_PLUGIN_SEARCH_ROW_DATA";
export const CHANGE_PLUGIN_BOOK_ROW_DATA = "CHANGE_PLUGIN_BOOK_ROW_DATA";
export const CHANGE_PLUGIN_CODE_STYLE = "CHANGE_PLUGIN_CODE_STYLE";
export const CHANGE_PLUGIN_BOOK_STYLE = "CHANGE_PLUGIN_BOOK_STYLE";
export const CHANGE_TO_BOOK_KIND = "CHANGE_TO_BOOK_KIND";
export const CHANGE_TO_BOOK_YEAR = "CHANGE_TO_BOOK_YEAR";
export const CHANGE_TO_BOOK_SEQNO = "CHANGE_TO_BOOK_SEQNO";
export const CHANGE_TO_SECTION = "CHANGE_TO_SECTION";
export const CHANGE_TO_REDIRECT_FLAG = "CHANGE_TO_REDIRECT_FLAG";
export const CHANGE_TO_REDIRECT_FLAG2 = "CHANGE_TO_REDIRECT_FLAG2";
export const CHANGE_TO_REDIRECT_TYPE = "CHANGE_TO_REDIRECT_TYPE";
export const TREE_DATA= "TREE_DATA";



export const changePluginTap = menuStep => ({
  type: CHANGE_PLUGIN_MENU_STEP,
  menuStep
});

export const prevPluginBookStep = bookStep => ({
  type: PREV_PLUGIN_BOOK_STEP,
  bookStep
});

export const nextPluginBookStep = bookStep => ({
  type: NEXT_PLUGIN_BOOK_STEP,
  bookStep
});

export const changePluginBookStep = bookStep => ({
  type: CHANGE_PLUGIN_BOOK_STEP,
  bookStep
});

export const prevPluginSearchStep = searchStep => ({
  type: PREV_PLUGIN_SEARCH_STEP,
  searchStep
});

export const nextPluginSearchStep = searchStep => ({
  type: NEXT_PLUGIN_SEARCH_STEP,
  searchStep
});

export const changePluginSearchStep = searchStep => ({
  type: CHANGE_PLUGIN_SEARCH_STEP,
  searchStep
});

export const changePluginBookNavData = (bookKind, bookYear, bookNm) => ({
  type: CHANGE_PLUGIN_BOOK_NAV_DATA,
  bookKind: bookKind,
  bookYear: bookYear,
  bookNm: bookNm
});
export const changePluginSearchNavData = (searchKind, searchYear, searchNm) => ({
  type: CHANGE_PLUGIN_SEARCH_NAV_DATA,
  searchKind: searchKind,
  searchYear: searchYear,
  searchNm: searchNm
});
export const changePluginSearchRowData = (searchRowData, searchStep) => ({
  type: CHANGE_PLUGIN_SEARCH_ROW_DATA,
  searchRowData: searchRowData,
  searchStep: searchStep
});

export const changePluginBookRowData = (bookRowData, bookStep) => ({
  type: CHANGE_PLUGIN_BOOK_ROW_DATA,
  bookRowData: bookRowData,
  bookStep: bookStep
});

export const changePluginCodeStyle = searchStyleStep => ({
  type: CHANGE_PLUGIN_CODE_STYLE,
  searchStyleStep
});
export const changePluginBookStyle = bookStyleStep => ({
  type: CHANGE_PLUGIN_BOOK_STYLE,
  bookStyleStep
});
export const changeToBookKind = (bookKind) => ({
  type: CHANGE_TO_BOOK_KIND,
  bookKind: bookKind
});

export const changeToBookYear = (bookYear) => ({
  type: CHANGE_TO_BOOK_YEAR,
  bookYear: bookYear
});

export const changeToBookSeqno = (bookSeqno) => ({
  type: CHANGE_TO_BOOK_SEQNO,
  bookSeqno: bookSeqno
});
export const changeToSection = (section) => ({
  type: CHANGE_TO_SECTION,
  section: section
});
export const changeToRedirectFlag = (redirectFlag) => ({
  type: CHANGE_TO_REDIRECT_FLAG,
  redirectFlag: redirectFlag
});
export const changeToRedirectFlag2 = (redirectFlag2) => ({
  type: CHANGE_TO_REDIRECT_FLAG2,
  redirectFlag2: redirectFlag2
});
export const changeToRedirectType = (redirectType) => ({
  type: CHANGE_TO_REDIRECT_TYPE,
  redirectType: redirectType
});
export default function reducer(state = {
  menuStep: '1',
  bookStep: '0',
  searchStep: '0',
  bookKind: '카달로그',
  bookYear: '',
  bookNm: '',
  searchKind: '',
  searchYear: '',
  searchStyleStep: '',
  bookStyleStep: '',
  searchNm: '',
  bookSeqno: '',
  section: '01',
  redirectFlag: false,
  redirectFlag2: false,
  redirectType: "LCD",
  searchRowData: [
    {
      "step": "0",
      "data": {},
    },
    {
      "step": "1",
      "data": {},
    },
    {
      "step": "2",
      "data": {},
    },
  ],
  searchRowData: [
    {
      "step": "0",
      "data": {},
    },
    {
      "step": "1",
      "data": {},
    },
    {
      "step": "2",
      "data": {},
    },
  ],
  bookRowData: [
    {
      "step": "0",
      "data": {},
      "prevBtn": "",
    },
    {
      "step": "1",
      "data": {},
      "prevBtn": "책선택으로",
    },
    {
      "step": "2",
      "data": {},
      "prevBtn": "브랜드로 돌아가기",
    },
    {
      "step": "3",
      "data": {},
      "prevBtn": "상품군 돌아가기",
    },
  ]
}, action) {
  switch (action.type) {
    case CHANGE_PLUGIN_MENU_STEP:
      return {
        ...state,
        menuStep: action.menuStep
      };
    case PREV_PLUGIN_BOOK_STEP:
      return {
        ...state,
        bookStep: (parseInt(action.bookStep) - 1) + ""
      };
    case NEXT_PLUGIN_BOOK_STEP:
      return {
        ...state,
        bookStep: (parseInt(action.bookStep) + 1) + ""
      };
    case CHANGE_PLUGIN_BOOK_STEP:
      return {
        ...state,
        bookStep: action.bookStep
      };
    case PREV_PLUGIN_SEARCH_STEP:
      return {
        ...state,
        searchStep: (parseInt(action.searchStep) - 1) + ""
      };
    case NEXT_PLUGIN_SEARCH_STEP:
      return {
        ...state,
        searchStep: (parseInt(action.searchStep) + 1) + ""
      };
    case CHANGE_PLUGIN_SEARCH_STEP:
      return {
        ...state,
        searchStep: action.searchStep
      };
    case CHANGE_PLUGIN_CODE_STYLE:
      return {
        ...state,
        searchStyleStep: action.searchStyleStep
      };
    case CHANGE_PLUGIN_BOOK_STYLE:
      return {
        ...state,
        bookStyleStep: action.bookStyleStep
      };
    case CHANGE_PLUGIN_BOOK_NAV_DATA:
      return {
        ...state,
        bookKind: action.bookKind,
        bookYear: action.bookYear,
        bookNm: action.bookNm
      };
    case CHANGE_PLUGIN_SEARCH_NAV_DATA:
      return {
        ...state,
        searchKind: action.searchKind,
        searchYear: action.searchYear,
        searchNm: action.searchNm
      };
    case CHANGE_TO_BOOK_KIND:
      return {
        ...state,
        bookKind: action.bookKind
      };
    case CHANGE_TO_BOOK_YEAR:
      return {
        ...state,
        bookYear: action.bookYear
      };
    case CHANGE_TO_BOOK_SEQNO:
      return {
        ...state,
        bookSeqno: action.bookSeqno
      };
    case CHANGE_TO_SECTION:
      return {
        ...state,
        section: action.section
    };
    case CHANGE_TO_REDIRECT_FLAG:
      return {
        ...state,
        redirectFlag: action.redirectFlag
      };
    case CHANGE_TO_REDIRECT_FLAG2:
      return {
        ...state,
        redirectFlag2: action.redirectFlag2
      };
    case CHANGE_TO_REDIRECT_TYPE:
      return {
        ...state,
        redirectType: action.redirectType
      };
    case CHANGE_PLUGIN_SEARCH_ROW_DATA:
      return {
        ...state,
        searchRowData: state.searchRowData.map((row) => {
          if (action.searchStep == row.step) {
            row.data = action.searchRowData;
          }
          return row;
        }),
      };
    case CHANGE_PLUGIN_BOOK_ROW_DATA:
      return {
        ...state,
        bookRowData: state.bookRowData.map((row) => {
          if (action.bookStep == row.step) {
            row.data = action.bookRowData;
          }
          return row;
        }),
      };
    default:
  }
  return state;
}
