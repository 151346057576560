export const CHANGE_TREE_DATA = "CHANGE_TREE_DATA";
export const CHANGE_TREE_CLICK_FUNCTION = "CHANGE_TREE_CLICK_FUNCTION";
export const CLICKABLE_PARENT_NODE = "CLICKABLE_PARENT_NODE";
export const VISIBILITY_TREE = "VISIBILITY_TREE";
export const INITIALIZED_TREE = "INITIALIZED_TREE";

export const CHANGE_TREE_DATA2 = "CHANGE_TREE_DATA2";
export const LOAD_TREE2 = "LOAD_TREE2";
export const CHANGE_TREE_CLICK_FUNCTION2 = "CHANGE_TREE_CLICK_FUNCTION2";
export const CLICKABLE_PARENT_NODE2 = "CLICKABLE_PARENT_NODE2";
export const VISIBILITY_TREE2 = "VISIBILITY_TREE2";
export const INITIALIZED_TREE2 = "INITIALIZED_TREE2";

export const LOAD_TREE = "LOAD_TREE";
export const CHANGE_IS_REAL_TREE = "CHANGE_IS_REAL_TREE";

export const loadTree = (treeData, pClickable, clickFunction) => ({
  type: LOAD_TREE,
  treeData: treeData,
  pClickable: pClickable,
  visibility: true,
  clickFunction: clickFunction,
});

/*
책상품관리 모달에 보여질 트리
@auth:장승희(2020.12.29.)
*/
export const loadTree2 = (treeData2, pClickable2, clickFunction2) => ({
  type: LOAD_TREE2,
  treeData2: treeData2,
  pClickable2: pClickable2,
  visibility2: true,
  clickFunction2: clickFunction2,
});

export const changeTreeData = (treeData) => ({
  type: CHANGE_TREE_DATA,
  treeData,
});

export const changeTreeData2 = (treeData2) => ({
  type: CHANGE_TREE_DATA2,
  treeData2,
});

export const initializedTree = () => ({
  type: INITIALIZED_TREE,
  treeData: [],
  pClickable: false,
  visibility: false,
  clickFunction: () => {},
});

export const initializedTree2 = () => ({
  type: INITIALIZED_TREE2,
  treeData2: [],
  pClickable2: false,
  visibility2: false,
  clickFunction2: () => {},
});

export const clickableParentNode = (pClickable) => ({
  type: CLICKABLE_PARENT_NODE,
  pClickable,
});

export const clickableParentNode2 = (pClickable2) => ({
  type: CLICKABLE_PARENT_NODE2,
  pClickable2,
});

export const visibilityTree = (visibility) => ({
  type: VISIBILITY_TREE,
  visibility,
});

export const visibilityTree2 = (visibility2) => ({
  type: VISIBILITY_TREE2,
  visibility2,
});

export const changeTreeClickFunction = (clickFunction) => ({
  type: CHANGE_TREE_CLICK_FUNCTION,
  clickFunction,
});

export const changeTreeClickFunction2 = (clickFunction2) => ({
  type: CHANGE_TREE_CLICK_FUNCTION2,
  clickFunction2,
});

export const changeIsRealTree = (isRealTree) => ({
  type: CHANGE_IS_REAL_TREE,
  isRealTree,
});

export default function reducer(
  state = {
    treeData: [],
    pClickable: false,
    visibility: false,

    treeData2: [],
    pClickable2: false,
    visibility2: false,
    clickFunction: () => {},
    isRealTree: false,
  },
  action
) {
  switch (action.type) {
    case LOAD_TREE:
    case INITIALIZED_TREE:
      return {
        ...state,
        treeData: action.treeData,
        pClickable: action.pClickable,
        visibility: action.visibility,
        clickFunction: action.clickFunction,
      };
    case CHANGE_TREE_DATA:
      return {
        ...state,
        treeData: action.treeData,
      };
    case CLICKABLE_PARENT_NODE:
      return {
        ...state,
        pClickable: action.pClickable,
      };
    case VISIBILITY_TREE:
      return {
        ...state,
        visibility: action.visibility,
      };
    case CHANGE_TREE_CLICK_FUNCTION:
      return {
        ...state,
        clickFunction: action.clickFunction,
      };

    case LOAD_TREE2:
    case INITIALIZED_TREE2:
      return {
        ...state,
        treeData2: action.treeData2,
        pClickable2: action.pClickable2,
        visibility2: action.visibility2,
        clickFunction2: action.clickFunction2,
      };
    case CHANGE_TREE_DATA2:
      return {
        ...state,
        treeData2: action.treeData2,
      };
    case CLICKABLE_PARENT_NODE2:
      return {
        ...state,
        pClickable2: action.pClickable2,
      };
    case VISIBILITY_TREE2:
      return {
        ...state,
        visibility2: action.visibility2,
      };
    case CHANGE_TREE_CLICK_FUNCTION2:
      return {
        ...state,
        clickFunction2: action.clickFunction2,
      };
    case CHANGE_IS_REAL_TREE:
      return {
        ...state,
        isRealTree: action.isRealTree,
      };
    default:
  }
  return state;
}
