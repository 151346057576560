import ThemeOptions from "./ThemeOptions";
import UserReducer from "./UserReducer";
import PluginNavReducer from "./PluginNavReducer";
import TreeReducer from "./TreeReducer";
import DcsNavReducer from "./DcsNavReducer";
import BookProductReducer from "./BookProductReducer";
import CategoryListReducer from "./CategoryListReducer";
import ModalReducer from "./ModalReducer";

export default {
  ThemeOptions,
  UserReducer,
  PluginNavReducer,
  TreeReducer,
  DcsNavReducer,
  BookProductReducer,
  ModalReducer
};
