import React, { Fragment, Suspense, lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";
 import { useSelector } from "react-redux";

const Default = lazy(() => import("../../Contents/Default"));
const Book = lazy(() => import("../../Contents/Book"));
const BookProduct = lazy(() => import("../../Contents/BookProduct"));
const Brand = lazy(() => import("../../Contents/Brand"));
const Section = lazy(() => import("../../Contents/Section"));
const Color = lazy(() => import("../../Contents/Color"));
const Styles = lazy(() => import("../../Contents/Styles"));
const Image = lazy(() => import("../../Contents/Image"));
const UseHistory = lazy(() => import("../../Contents/UseHistory"));
const Login = lazy(() => import("../../Contents/Users/Login"));

const AppMain = () => {
  const userReducer = useSelector((state)=>state.UserReducer);
  return (
    <Fragment>
      {/* Components */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/default" component={Default} /> */}
       {userReducer.loggedIn ? <Route path="/default" component={Default} /> : <Redirect to="/login" />} 
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/book" component={Book} /> */}
        {userReducer.loggedIn ? <Route path="/book" component={Book} /> : <Redirect to="/login" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/bookProduct" component={BookProduct} />  */}
        {userReducer.loggedIn ? <Route path="/bookProduct" component={BookProduct} /> : <Redirect to="/login" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/brand" component={Brand} /> */}
        {userReducer.loggedIn && userReducer.auth === 'Y' ? <Route path="/brand" component={Brand} /> : <Redirect to="/login" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/section" component={Section} /> */}
        {userReducer.loggedIn && userReducer.auth === 'Y'? <Route path="/section" component={Section} /> : <Redirect to="/section" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/color" component={Color} /> */}
        {userReducer.loggedIn && userReducer.auth === 'Y'? <Route path="/color" component={Color} /> : <Redirect to="/color" />}
       
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
         {/*<Route path="/styles" component={Styles} /> */}
        {userReducer.loggedIn && userReducer.auth === 'Y'? <Route path="/styles" component={Styles} /> : <Redirect to="/styles" />}
      
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        {/* <Route path="/image" component={Image} /> */}
         {userReducer.loggedIn && userReducer.auth === 'Y'? <Route path="/image" component={Image} /> : <Redirect to="/image" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
          {/* <Route path="/useHistory" component={UseHistory} /> */}
         {userReducer.loggedIn ? <Route path="/image" component={Image} /> : <Redirect to="/image" />}
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        <Route path="/login" component={Login} /> 
          {userReducer.loggedIn ? <Route path="/login" component={Login} /> : <Redirect to="/login" />}
      </Suspense>

    {/* 개발할때 사용 */}
    {/* <Route exact path="/" render={() => <Redirect to="/Login" />}/> 
    <Route exact path="/login" render={() => <Redirect to="/default" />} /> */}
      
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
