import {
  USER_LOGIN_FALSE,
  USER_LOGIN_TRUE,
} from "../actions/UserReducer/UserActionTypes";
import * as Cookies from "js-cookie";

const sessionCookie = Cookies.get("session");
const initialState = {
  id: "",
  name: "",
  auth:"",
  // loggedIn: sessionCookie === undefined ? false : true
  loggedIn: true
  //loggedIn: sessionStorage.getItem("ctxlogin") ? true : false,
};

const UserReducer = (state = initialState, action) => {

  switch (action.type) {
    case USER_LOGIN_TRUE:
      return loginTrue(state, action);
    case USER_LOGIN_FALSE:
      return loginFalse(state, action);
    default:
      return state;
  }
};

const loginTrue = (state, action) => {
  return {
    ...state,
    loggedIn: true,
    id: action.id,
    name: action.name,
    auth: action.auth
  };
};

const loginFalse = (state, action) => {
  return {
    ...state,
    loggedIn: false,
    id: "",
    name: "",
    auth:""
  };
};

export default UserReducer;
